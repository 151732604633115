<template>
    <div class="container">
        <div class="write-review">
            <div class="left">
                <div class="title">Write A Review For This Product</div>
                <div class="divide-line"></div>
                <el-form :model="form" :rules="rules" ref="form" label-position="top" :hide-required-asterisk="true" class="form">
                    <el-form-item label="Your name" prop="comment_name">
                        <el-input v-model="form.comment_name" placeholder="Please fill in your name"></el-input>
                    </el-form-item>
                    <el-form-item label="Your rating" prop="comment_rate">
                        <div class="rate-box">
                            <el-rate v-model="form.comment_rate"></el-rate>
                        </div>
                    </el-form-item>
                    <el-form-item label="Write your review" prop="comment_review">
                        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10}" v-model="form.comment_review"
                            placeholder="Description your experience..."></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="operate">
                            <div class="submit-btn" @click="submitForm">Submit</div>
                            <div class="reset-btn" @click="resetForm">Reset</div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div class="right">
                <div class="title">Product Detail</div>
                <div class="divide-line"></div>
                <div class="product-info" @click="toDetail">
                    <img class="img" :src="curGoods.pic_url" />
                    <div class="name">{{curGoods.good_title}}</div>
                </div>
            </div>
        </div>
        <div class="comment-list">
            <div class="title">All comments</div>
            <div v-for="(item,index) in list" :key="index" class="comment-item">
                <div class="user">
                    <el-avatar class="avatar" icon="el-icon-user-solid"></el-avatar>
                    <div class="username">{{item.comment_name}}</div>
                </div>
                <div class="rate">
                    <el-rate :value="item.comment_rate" disabled></el-rate>
                </div>
                <div class="content">{{item.comment_review}}</div>
            </div>
            <div class="pagination-box">
                <el-pagination
                class="pagination-pc" 
                :hide-on-single-page="true"
                :current-page="currentPage"
                :pager-count="11"
                :page-size="pageSize"
                :total="total" 
                @current-change="handleCurrentChange" 
                background 
                layout="prev, pager, next">
                </el-pagination>
                <el-pagination
                class="pagination-h5" 
                :hide-on-single-page="true"
                :current-page="currentPage"
                :pager-count="5"
                :page-size="pageSize"
                :total="total" 
                @current-change="handleCurrentChange" 
                background 
                layout="prev, pager, next">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                goods_id: '',
                curGoods: {},
                form: {
                    comment_name: '',
                    comment_rate: 5,
                    comment_review: ''
                },
                rules: {
                    comment_name: [{
                        required: true,
                        message: 'Please fill in your name',
                        trigger: 'blur'
                    }],
                    comment_review: [{
                        required: true,
                        message: 'Description your experience...',
                        trigger: 'blur'
                    }]
                },
                list: [],
                pageSize: 0,
                total: 0,
                currentPage: 1
            }
        },
        created() {
            this.goods_id = this.$route.query.id || ''
            this.getCurGoods()
            this.getList()
        },
        computed: {
            curGoodsId() {
                return this.$route.query.id
            }
        },
        watch: {
            curGoodsId(val) {
                // console.log(val)
                this.goods_id = val
                this.getCurGoods()
                this.currentPage = 1
                this.getList()
            }
        },
        methods: {
            getCurGoods() {
                this.$api.goods_detail({
                    good_id: this.goods_id
                },true).then(res => {
                    this.curGoods = res.data.good
                })
            },
            
            getList(bool=false) {
                this.$api.comment_list({
                    good_id: this.goods_id,
                    page: this.currentPage
                },bool).then(res => {
                    this.list = res.data.data
                    this.pageSize = res.data.per_page
                    this.total = res.data.total
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getList(true)
            },
            
            submitForm() {
                this.$refs.form.validate((valid) => {
                    if(valid) {
                        let form = Object.assign(this.form,{ good_id: this.goods_id })
                        this.$api.comment(form,true).then(res => {
                            this.resetForm()
                            this.$message({
                                message: res.message,
                                type: 'success'
                            })
                            this.currentPage = 1
                            this.getList()
                        })
                    }
                })
            },
            
            resetForm() {
                this.$refs.form.resetFields()
            },
            
            toDetail() {
                this.$router.push({
                    path: '/detail',
                    query: {
                        id: this.goods_id
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
            width: 100%;
            box-sizing: border-box;
            padding: 4rem 0;
            
            .write-review {
                display: flex;
                width: 100%;
                margin-bottom: 2rem;
                
                .title {
                    width: 100%;
                    font-size: 1.8rem;
                    font-weight: bold;
                    color: #333333;
                    word-wrap: break-word;
                }
                
                .divide-line {
                    width: 100%;
                    height: 1px;
                    margin: 2.4rem 0;
                    background-color: #e9e9e9;
                }
            
                .left {
                    width: calc(100% - 37.5rem);
                    box-sizing: border-box;
                    padding: 3rem;
                    border: 1px solid #e9e9e9;
                    border-radius: 5px;
                    background-color: #FFFFFF;
                    
                    .form {
                        width: 100%;
                        
                        /deep/ .el-form-item {
                            margin-bottom: 3rem;
                        }
                        
                        .rate-box {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 4rem;
                            
                            /deep/ .el-rate__icon {
                                font-size: 2rem;
                            }
                        }
                        
                        .operate {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            
                            .submit-btn {
                                min-width: 8rem;
                                height: 4rem;
                                line-height: 4rem;
                                box-sizing: border-box;
                                padding: 0 2rem;
                                border-radius: 4px;
                                font-size: 1.4rem;
                                color: #FFFFFF;
                                text-align: center;
                                white-space: nowrap;
                                background-color: #000000;
                                cursor: pointer;
                            }
                            
                            .reset-btn {
                                min-width: 8rem;
                                height: 4rem;
                                line-height: 4rem;
                                box-sizing: border-box;
                                padding: 0 2rem;
                                margin-left: 1.5rem;
                                border: 1px solid #dcdfe6;
                                border-radius: 4px;
                                font-size: 1.4rem;
                                color: #666666;
                                text-align: center;
                                white-space: nowrap;
                                background-color: #FFFFFF;
                                cursor: pointer;
                            }
                        }
                    }
                }
            
                .right {
                    width: 35.5rem;
                    box-sizing: border-box;
                    padding: 3rem;
                    margin-left: 2rem;
                    border: 1px solid #e9e9e9;
                    border-radius: 5px;
                    background-color: #FFFFFF;
                    
                    .product-info {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        cursor: pointer;
                        
                        .img {
                            min-width: 10rem;
                            max-width: 10rem;
                            height: 10rem;
                            margin: .6rem 0 3rem;
                        }
                        
                        .name {
                            width: 100%;
                            font-size: 1.4rem;
                            color: #333333;
                            word-wrap: break-word;
                            text-decoration: underline;
                        }
                    }
                }
            }
            
            .comment-list {
                width: 100%;
                box-sizing: border-box;
                padding: 3rem;
                border: 1px solid #e9e9e9;
                border-radius: 5px;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 3rem;
                    font-size: 1.8rem;
                    font-weight: bold;
                    color: #333333;
                    word-wrap: break-word;
                }
                
                .comment-item {
                    width: 100%;
                    cursor: default;
                    
                    &:not(:last-child) {
                        margin-bottom: 3rem;
                    }
                    
                    .user {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        
                        .avatar {
                        	min-width: 3.6rem;
                        	max-width: 3.6rem;
                        	height: 3.6rem;
                        	line-height: 3.6rem;
                        	margin-right: .6rem;
                        }
                        
                        /deep/ .el-avatar--icon {
                        	font-size: 1.8rem;
                        }
                        
                        .username {
                            max-width: 100%;
                            font-size: 1.5rem;
                            font-weight: bold;
                            color: #333333;
                            word-break: break-all;
                        }
                    }
                    
                    .rate {
                        width: 100%;
                        margin: 1rem 0 1.2rem;
                        
                        /deep/ .el-rate__icon {
                            font-size: 1.8rem;
                        }
                    }
                    
                    .content {
                        width: 100%;
                        font-size: 1.4rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                }
                
                .pagination-box {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    
                    .pagination-h5 {
                        display: none;
                    }
                }
            }
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
            width: 100%;
            box-sizing: border-box;
            padding: 1rem;
            background-color: #f6f6f8;
            
            .write-review {
                display: flex;
                flex-direction: column-reverse;
                width: 100%;
                margin-bottom: 1rem;
                
                .title {
                    width: 100%;
                    font-size: 1.6rem;
                    font-weight: bold;
                    color: #333333;
                    word-wrap: break-word;
                }
                
                .divide-line {
                    width: 100%;
                    height: 1px;
                    margin: 2.4rem 0;
                    background-color: #e9e9e9;
                }
            
                .left {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 2.5rem $h5-box-padding;
                    border-radius: 5px;
                    background-color: #FFFFFF;
                    
                    .form {
                        width: 100%;
                        
                        /deep/ .el-form-item {
                            margin-bottom: 2.5rem;
                        }
                        
                        .rate-box {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 4rem;
                            
                            /deep/ .el-rate__icon {
                                font-size: 2rem;
                            }
                        }
                        
                        .operate {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            
                            .submit-btn {
                                width: 48%;
                                height: 4rem;
                                line-height: 4rem;
                                box-sizing: border-box;
                                padding: 0 2rem;
                                border-radius: 4px;
                                font-size: 1.4rem;
                                color: #FFFFFF;
                                text-align: center;
                                white-space: nowrap;
                                background-color: #000000;
                                cursor: pointer;
                            }
                            
                            .reset-btn {
                                width: 48%;
                                height: 4rem;
                                line-height: 4rem;
                                box-sizing: border-box;
                                padding: 0 2rem;
                                margin-left: 1.5rem;
                                border: 1px solid #dcdfe6;
                                border-radius: 4px;
                                font-size: 1.4rem;
                                color: #666666;
                                text-align: center;
                                white-space: nowrap;
                                background-color: #FFFFFF;
                                cursor: pointer;
                            }
                        }
                    }
                }
            
                .right {
                    width: 100%;
                    box-sizing: border-box;
                    padding: 2.5rem $h5-box-padding;
                    margin-bottom: 1rem;
                    border-radius: 5px;
                    background-color: #FFFFFF;
                    
                    .product-info {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        cursor: pointer;
                        
                        .img {
                            min-width: 10rem;
                            max-width: 10rem;
                            height: 10rem;
                            margin: .6rem 0 3rem;
                        }
                        
                        .name {
                            width: 100%;
                            font-size: 1.4rem;
                            color: #333333;
                            word-wrap: break-word;
                            text-decoration: underline;
                        }
                    }
                }
            }
            
            .comment-list {
                width: 100%;
                box-sizing: border-box;
                padding: 2.5rem $h5-box-padding;
                border-radius: 5px;
                background-color: #FFFFFF;
                
                .title {
                    width: 100%;
                    margin-bottom: 2.5rem;
                    font-size: 1.6rem;
                    font-weight: bold;
                    color: #333333;
                    word-wrap: break-word;
                }
                
                .comment-item {
                    width: 100%;
                    cursor: default;
                    
                    &:not(:last-child) {
                        margin-bottom: 2.5rem;
                    }
                    
                    .user {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        
                        .avatar {
                        	min-width: 3.2rem;
                        	max-width: 3.2rem;
                        	height: 3.2rem;
                        	line-height: 3.2rem;
                        	margin-right: .6rem;
                        }
                        
                        /deep/ .el-avatar--icon {
                        	font-size: 1.6rem;
                        }
                        
                        .username {
                            max-width: 100%;
                            font-size: 1.4rem;
                            font-weight: bold;
                            color: #333333;
                            word-break: break-all;
                        }
                    }
                    
                    .rate {
                        width: 100%;
                        margin: .8rem 0;
                        
                        /deep/ .el-rate__icon {
                            font-size: 1.8rem;
                        }
                    }
                    
                    .content {
                        width: 100%;
                        font-size: 1.3rem;
                        color: #333333;
                        word-wrap: break-word;
                    }
                }
                
                .pagination-box {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    
                    .pagination-pc {
                        display: none;
                    }
                }
            }
        }
    }
</style>
